import React from 'react';
import { Grid, Card, Chip, CardMedia, CardContent, Typography, IconButton, CardActions, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const MenuItem = ({ item, addToCart, removeFromCart }) => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: '16px' }}>
      <CardMedia
        component="img"
        sx={{ height: 200, objectFit: 'cover' }}
        image={item.image || '/path/to/placeholder-image.jpg'}
        alt={item.name || 'Menu Item'}
      />
      <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
        <Typography variant="h6">{item.name || 'Unnamed Item'}</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Código: {item.codigo || 'N/A'}
        </Typography>
        {item.description && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {item.description}
          </Typography>
        )}
        {/* <Typography variant="subtitle1" sx={{ mt: 1 }}>
          ${(item.price || 0).toFixed(2)}
        </Typography> */}
      </CardContent>
      <CardActions sx={{ mt: 'auto', justifyContent: 'center' }}>
        <IconButton onClick={() => removeFromCart(item)} size="small">
          <RemoveIcon />
        </IconButton>
        <Typography variant="body1" component="span" sx={{ mx: 1 }}>
          {item.quantity || 0}
        </Typography>
        <IconButton onClick={() => addToCart(item)} size="small">
          <AddIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const Menu = ({ menuItems, addToCart, removeFromCart, searchTerm, selectedCategories = [], onCategoryToggle }) => {
  const filteredItems = menuItems
    .filter(item => item.status === 'enabled')
    .filter(item => {
      const searchLower = searchTerm.toLowerCase();
      const nameLower = (item.name || '').toLowerCase();
      const descriptionLower = (item.description || '').toLowerCase();
      const codigoLower = (item.codigo || '').toLowerCase();

      return (nameLower.includes(searchLower) || 
              descriptionLower.includes(searchLower) || 
              codigoLower.includes(searchLower)) &&
             (selectedCategories.length === 0 || selectedCategories.includes(item.category));
    });

  return (
    <Box sx={{ 
      backgroundColor: '#f5f5f5', 
      height: '100%',
      overflowY: 'auto',
      padding: 2,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
        {['Repostería', 'Panadería', 'Cafetería'].map((category) => (
          <Chip
            key={category}
            label={category}
            onClick={() => onCategoryToggle(category)}
            color={selectedCategories.includes(category) ? "primary" : "default"}
            sx={{ 
              fontSize: '16px', backgroundColor: selectedCategories.includes(category) ? '#d60f16' : 'default',
              '&:hover': {
                backgroundColor: selectedCategories.includes(category) ? '#b60d13' : 'default',
              },
            }}
          />
        ))}
      </Box>
      <Grid container spacing={2}>
        {filteredItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.firebaseId}>
            <MenuItem item={item} addToCart={addToCart} removeFromCart={removeFromCart} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Menu;