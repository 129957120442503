import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  TextField, 
  Button
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { addDays, setHours, setMinutes, isAfter, isSameDay } from 'date-fns';

const OrderModal = ({ open, onClose, onSubmit, userInfo, userPhone }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [notes, setNotes] = useState('');
  const [timeError, setTimeError] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email || '');
      setName(userInfo.name || '');
      setIdNumber(userInfo.idNumber || '');
      setPhone(userInfo.phone || '');
    } else {
      setPhone(userPhone);
    }
  }, [userInfo, userPhone]);

  const handleSubmit = () => {
    if (validateDeliveryTime()) {
      onSubmit({ email, name, idNumber, phone, deliveryDate, notes });
      onClose();
    }
  };

  const validateDeliveryTime = () => {
    if (!deliveryDate) {
      setTimeError(true);
      return false;
    }

    const now = new Date();
    const tomorrow = addDays(now, 1);
    const minTime = setHours(setMinutes(tomorrow, 0), 12);

    if (isSameDay(deliveryDate, tomorrow) && !isAfter(deliveryDate, minTime)) {
      setTimeError(true);
      return false;
    }

    setTimeError(false);
    return true;
  };

  const handleDateChange = (newValue) => {
    setDeliveryDate(newValue);
    setTimeError(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4, boxShadow: 24 }}>
        <Typography variant="h6" gutterBottom>Detalles Personales</Typography>
        <TextField fullWidth label="Correo" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
        <TextField fullWidth label="Nombre" value={name} onChange={(e) => setName(e.target.value)} margin="normal" />
        <TextField fullWidth label="Cedula" value={idNumber} onChange={(e) => setIdNumber(e.target.value)} margin="normal" />
        <TextField fullWidth label="Teléfono" value={phone} onChange={(e) => setPhone(e.target.value)} margin="normal" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Fecha y Hora de Entrega"
            value={deliveryDate}
            onChange={handleDateChange}
            onAccept={validateDeliveryTime}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
                error: timeError,
                helperText: timeError ? "La hora de entrega debe ser después de las 12 PM del día siguiente" : ""
              }
            }}
            minDateTime={addDays(new Date(), 1)}
            views={['year', 'month', 'day', 'hours', 'minutes']}
          />
        </LocalizationProvider>
        <TextField fullWidth label="Notas" value={notes} onChange={(e) => setNotes(e.target.value)} margin="normal" multiline rows={4} />
        <Button 
          variant="contained" 
          fullWidth 
          onClick={handleSubmit} 
          sx={{ 
            mt: 2, 
            backgroundColor:'#d60f16', 
            '&:hover': { backgroundColor: '#b60d13' },
          }}
        >
          Enviar Orden
        </Button>
      </Box>
    </Modal>
  );
};

export default OrderModal;