import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const reprintOrder = async (orderId) => {
  try {
    const response = await axios.post(`${API_URL}/reprint-order/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error reprinting order:', error);
    throw error;
  }
};

export const addOrderHistoryEvents = async (orderId, historyEvents) => {
  try {
    const response = await axios.post(`${API_URL}/order/${orderId}/history`, historyEvents);
    return response.data;
  } catch (error) {
    console.error('Error adding order history events:', error);
    throw error;
  }
};

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await axios.post(`${API_URL}/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.imageUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const fetchMenu = () => axios.get(`${API_URL}/menu`)
  .then(res => res.data)
  .catch(error => {
    console.error('Error fetching menu:', error);
    throw error;
  });

export const fetchCart = () => axios.get(`${API_URL}/cart`).then(res => res.data);

export const fetchOrders = () => axios.get(`${API_URL}/orderHistory`)
  .then(res => res.data)
  .catch(error => {
    console.error('Error fetching orders:', error);
    throw error;
  });

export const updateCart = (cart) => axios.post(`${API_URL}/cart`, cart);

export const submitOrder = (orderData) => axios.post(`${API_URL}/order`, orderData)
  .then(res => res.data)
  .catch(error => {
    console.error('Error submitting orders:', error);
    throw error;
  });

export const fetchClientInfo = (phone) => axios.post(`${API_URL}/getClientInfo`, { phone })
  .then(res => res.data)
  .catch(error => {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      console.error('Error fetching client info:', error.response ? error.response.data : error.message);
      throw error;
    }
  });

export const triggerEvent = async (phone, orderId) => {
  try {
    const response = await axios.post(`${API_URL}/triggerEvent`, { phone, orderId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clientRegister = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/clientRegister`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering client:', error);
    throw error;
  }
};

export const adminRegister = async (adminData) => {
  try {
    const response = await axios.post(`${API_URL}/admin/register`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error registering admin:', error);
    throw error;
  }
};

export const adminLogin = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in admin:', error);
    throw error;
  }
};

export const adminLogout = async () => {
  try {
    const response = await axios.post(`${API_URL}/admin/logout`);
    return response.data;
  } catch (error) {
    console.error('Error logging out admin:', error);
    throw error;
  }
};

export const updateOrder = async (updatedOrder) => {
  try {
    const response = await axios.put(`${API_URL}/order/${updatedOrder.orderId}`, updatedOrder);
    return response.data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const cancelOrder = async (orderId) => {
  try {
    const response = await axios.put(`${API_URL}/order/${orderId}/cancel`);
    return response.data;
  } catch (error) {
    console.error('Error canceling order:', error);
    throw error;
  }
};

export const fetchProducts = () => axios.get(`${API_URL}/products`)
  .then(res => res.data)
  .catch(error => {
    console.error('Error fetching products:', error);
    throw error;
  });

export const addProduct = (product) => axios.post(`${API_URL}/products`, product)
  .then(res => res.data)
  .catch(error => {
    console.error('Error adding product:', error);
    throw error;
  });

export const updateProduct = async (product) => {
  try {
    const response = await axios.put(`${API_URL}/products/${product.firebaseId}`, product);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const deleteProduct = (firebaseId) => axios.delete(`${API_URL}/products/${firebaseId}`)
  .then(res => res.data)
  .catch(error => {
    console.error('Error deleting product:', error);
    throw error;
  });