// src/components/PreMenuModal/PreMenuModal.js

import React from 'react';
import { Dialog, Grid, Card, CardContent, Typography, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';

const categories = [
  { name: 'Repostería', image: '/img/reposteria.jpg' },
  { name: 'Panadería', image: '/img/panaderia.jpg' },
  { name: 'Cafetería', image: '/img/cafeteria.jpg' },
];

const BlurredDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
  },
}));

const PreMenuModal = ({ open, onClose, onCategorySelect }) => {
  return (
    <BlurredDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'rgba(255, 255, 255, 0.0)',
          boxShadow: 'none',
        }
      }}
    >
      <Grid 
        container 
        spacing={2} 
        sx={{ 
          p: 2, 
          background: 'transparent'
        }}
      >
        {categories.map((category) => (
          <Grid item xs={12} md={4} key={category.name}>
            <Card 
              onClick={() => onCategorySelect(category.name)}
              sx={{ 
                cursor: 'pointer', 
                height: '200px', 
                position: 'relative',
                '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' },
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '8px',
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={category.image}
                alt={category.name}
                sx={{ filter: 'brightness(0.9)' }}
              />
              <CardContent 
                sx={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  right: 0,
                  bottom: 0,
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(0, 0, 0, 0.2)'
                }}
              >
                <Typography 
                  variant="h5" 
                  component="div"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
                  }}
                >
                  {category.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </BlurredDialog>
  );
};

export default PreMenuModal;