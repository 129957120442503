import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, TextField, Select, MenuItem, Modal, CircularProgress, Switch, FormControlLabel
} from '@mui/material';
import Header from '../../components/Header/Header';
import SideMenu from '../../components/SideMenu/SideMenu';
import { fetchProducts, updateProduct, deleteProduct, addProduct, uploadImage } from '../../services/api';
import { useAdminAuth } from '../../context/AdminAuthContext';

const categories = ['Repostería', 'Panadería', 'Cafetería'];

const AdminProductPage = () => {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { adminUser } = useAdminAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminUser || adminUser.role !== 'supervisor') {
      navigate('/');
    } else {
      loadProducts();
    }
  }, [adminUser, navigate]);

  const loadProducts = async () => {
    setIsLoading(true);
    try {
      const fetchedProducts = await fetchProducts();
      setProducts(fetchedProducts);
    } catch (error) {
      console.error('Error al cargar productos:', error);
    }
    setIsLoading(false);
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setIsModalOpen(true);
  };
  
  const handleSave = async () => {
    try {
      if (editingProduct.firebaseId) {
        await updateProduct(editingProduct);
      } else {
        await addProduct(editingProduct);
      }
      setIsModalOpen(false);
      loadProducts();
    } catch (error) {
      console.error('Error al guardar producto:', error);
    }
  };
  
  const handleDelete = async (firebaseId) => {
    try {
      await deleteProduct(firebaseId);
      loadProducts();
    } catch (error) {
      console.error('Error al eliminar producto:', error);
    }
  };

  const handleNewProduct = () => {
    setEditingProduct({ name: '', price: 0, image: '', codigo: '', category: '' });
    setIsModalOpen(true);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageUrl = await uploadImage(file);
        setEditingProduct({ ...editingProduct, image: imageUrl });
      } catch (error) {
        console.error('Error al subir imagen:', error);
      }
    }
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleStatusChange = async (product) => {
    const updatedProduct = { 
      ...product, 
      status: product.status === 'enabled' ? 'disabled' : 'enabled' 
    };
    try {
      await updateProduct(updatedProduct);
      loadProducts();
    } catch (error) {
      console.error('Error al actualizar el estado del producto:', error);
    }
  };


  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Header search={searchTerm} onSearchChange={handleSearchChange} />
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          <Button variant="contained" color="primary" onClick={handleNewProduct} sx={{ mb: 2, backgroundColor: '#d60f16',
                            '&:hover': {
                              backgroundColor: '#b60d13',
                            }, }}>
            Agregar Nuevo Producto
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>ID</TableCell> */}
                    <TableCell>Nombre</TableCell>
                    <TableCell>Precio</TableCell>
                    <TableCell>Código</TableCell>
                    <TableCell>Categoría</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.filter(product => 
                    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    product.codigo.toLowerCase().includes(searchTerm.toLowerCase())
                  ).map((product) => (
                    <TableRow key={product.firebaseId}>
                      {/* <TableCell>{product.id}</TableCell> */}
                      <TableCell>{product.name}</TableCell>
                      <TableCell>${product.price.toFixed(2)}</TableCell>
                      <TableCell>{product.codigo}</TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell>
                        <FormControlLabel
                        control={
                            <Switch
                            checked={product.status === 'enabled'}
                            onChange={() => handleStatusChange(product)}
                            color="primary"
                            />
                        }
                        label={product.status === 'enabled' ? 'Habilitado' : 'Deshabilitado'}
                        />
                    </TableCell>
                      <TableCell>
                        <Button onClick={() => handleEdit(product)} sx={{ color: '#d60f16',
                            '&:hover': {
                              color: '#d60f16', backgroundColor: '#d60f160a',
                            }, }}>Editar</Button>
                        <Button onClick={() => handleDelete(product.firebaseId)} sx={{ color: '#d60f16',
                            '&:hover': {
                              color: '#d60f16', backgroundColor: '#d60f160a',
                            }, }}>Eliminar</Button>
                    </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" gutterBottom>
            {editingProduct?.id ? 'Editar Producto' : 'Agregar Nuevo Producto'}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Nombre"
            value={editingProduct?.name || ''}
            onChange={(e) => setEditingProduct({ ...editingProduct, name: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Precio"
            type="number"
            value={editingProduct?.price || ''}
            onChange={(e) => setEditingProduct({ ...editingProduct, price: parseFloat(e.target.value) })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Código"
            value={editingProduct?.codigo || ''}
            onChange={(e) => setEditingProduct({ ...editingProduct, codigo: e.target.value })}
          />
          <input
            accept="image/*"
            type="file"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="image-upload"
          />
          <label htmlFor="image-upload">
            <Button variant="contained" component="span" fullWidth sx={{ mt: 2, backgroundColor: '#d60f16',
                            '&:hover': {
                              backgroundColor: '#b60d13',
                            }, }}>
              Subir Imagen
            </Button>
          </label>
          {editingProduct?.image && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <img src={editingProduct.image} alt="Producto" style={{ maxWidth: '100%', maxHeight: 200 }} />
            </Box>
          )}
          <Select
            fullWidth
            margin="normal"
            value={editingProduct?.category || ''}
            onChange={(e) => setEditingProduct({ ...editingProduct, category: e.target.value })}
            sx={{ mt: 2 }}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
          <Button onClick={handleSave} fullWidth variant="contained" sx={{ mt: 2, backgroundColor: '#d60f16',
                            '&:hover': {
                              backgroundColor: '#b60d13',
                            }, }}>Guardar</Button>
          <Button onClick={() => setIsModalOpen(false)} fullWidth variant="outlined" sx={{ mt: 1, borderColor: '#d60f16', color: '#d60f16',
                            '&:hover': {
                              color: '#d60f16', borderColor: '#d60f16', backgroundColor: '#d60f160a',
                            }, }}>Cancelar</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminProductPage;