import React from 'react';
import { AppBar, Toolbar, Typography, InputBase } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const Header = ({ search, onSearchChange, showSearch = true }) => {
  const location = useLocation();

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Inicio';
      case '/orderHistory':
        return 'Historial';
      case '/admin/products':
        return 'Inventario';
      default:
        return 'Almacenes Unidos';
    }
  };

  return (
    <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: '#ffffff', borderBottom: '2px solid #f5f5f5'}}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {getPageTitle()}
        </Typography>
        {showSearch && (
          <div style={{ position: 'relative', backgroundColor: '#f1f3f4', marginRight: '16px', borderRadius: '50px' }}>
            <div style={{ padding: '0 16px', height: '100%', position: 'absolute', pointerEvents: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              inputProps={{ 'aria-label': 'search' }}
              style={{ padding: '8px 8px 8px 48px', width: '100%' }}
              value={search}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;