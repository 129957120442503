import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, Button, IconButton, Chip, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import SideMenu from '../../components/SideMenu/SideMenu';
import OrderModal from '../../components/OrderModal/OrderModal';
import LoginModal from '../../components/Login/LoginModal';
import PreMenuModal from '../../components/PreMenuModal/PreMenuModal';
import { fetchMenu, fetchCart, updateCart, submitOrder, fetchClientInfo, clientRegister } from '../../services/api';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const HomePage = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [phoneInput, setPhoneInput] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [preMenuModalOpen, setPreMenuModalOpen] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const refreshPage = () => {
    window.location.reload();
  };
  

  useEffect(() => {
    const loadData = async () => {
      try {
        const [menuData, cartData] = await Promise.all([fetchMenu(), fetchCart()]);
        const initialMenuItems = Array.isArray(menuData) ? menuData : [];
        const initialCartItems = Array.isArray(cartData) ? cartData : [];

        const updatedMenuItems = initialMenuItems.map(item => ({
          ...item,
          quantity: initialCartItems.find(cartItem => cartItem.id === item.id)?.quantity || 0
        }));

        setMenuItems(updatedMenuItems);
        setCartItems(initialCartItems);
      } catch (error) {
        console.error('Error loading data:', error);
        setMenuItems([]);
        setCartItems([]);
      }
    };
    loadData();
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategories([category]);
    setPreMenuModalOpen(false);
  };

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  }

  const filteredMenuItems = menuItems.filter(item => 
    selectedCategories.length === 0 || selectedCategories.includes(item.category)
  );

  useEffect(() => {
    if (userPhone) {
      fetchClientInfo(userPhone)
        .then(data => {
          setUserInfo(data);
        })
        .catch(error => {
          console.error('Error fetching client info:', error);
        });
    }
  }, [userPhone]);

  const updateMenuItemQuantity = useCallback((itemFirebaseId, newQuantity) => {
    setMenuItems(prevItems =>
      prevItems.map(item =>
        item.firebaseId === itemFirebaseId ? { ...item, quantity: newQuantity } : item
      )
    );
  }, []);

  const addToCart = useCallback((item) => {
    setCartItems((prevItems) => {
      const updatedCart = [...prevItems];
      const existingItem = updatedCart.find((i) => i.firebaseId === item.firebaseId);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        updatedCart.push({ ...item, quantity: 1 });
      }
      updateCart(updatedCart);
      return updatedCart;
    });
  
    updateMenuItemQuantity(item.firebaseId, (item.quantity || 0) + 1);
  }, [updateMenuItemQuantity]);

  const removeFromCart = useCallback((item) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems
        .map((i) => (i.firebaseId === item.firebaseId ? { ...i, quantity: i.quantity - 1 } : i))
        .filter((i) => i.quantity > 0);
      updateCart(updatedCart);
      return updatedCart;
    });
  
    updateMenuItemQuantity(item.firebaseId, Math.max((item.quantity || 0) - 1, 0));
  }, [updateMenuItemQuantity]);

  const handlePlaceOrder = () => {
    if (cartItems.length === 0) {
      setErrorMessage('El carrito está vacío. Por favor agregue artículos antes de realizar una orden.');
      setErrorDialogOpen(true);
      return;
    }

    if (userPhone) {
      setOrderModalOpen(true);
    } else {
      setLoginModalOpen(true);
    }
  };

  const handleLogin = async (phone) => {
    setPhoneInput('');
    setUserPhone(phone);
    try {
      const response = await fetchClientInfo(phone);
      setUserInfo(response);
      setOrderModalOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // User not found, proceed to order with the phone number
        setLoginModalOpen(false);
        setOrderModalOpen(true);
      } else {
        console.error('Error fetching client info:', error);
      }
    }
  };

  const handleOrderSubmit = async (userData) => {
    try {
        if (!userInfo) {
            const registerResponse = await clientRegister(userData);
            setUserInfo(registerResponse);
        }

        const orderData = {
            ...userData,
            cart: cartItems,
            paymentStatus: 'Pendiente', // Default value
            orderStatus: 'Pendiente', // Default value
        };

        const submitResponse = await submitOrder(orderData);
        if (submitResponse.success) {
            setConfirmDialogOpen(true);
            setCartItems([]);
            setMenuItems(prevItems => prevItems.map(item => ({ ...item, quantity: 0 })));
            setUserPhone('');
            setUserInfo(null);
        } else {
            setErrorMessage('Error placing order. Please try again.');
            setErrorDialogOpen(true);
        }
    } catch (error) {
        console.error('Order submission error:', error);
        setErrorMessage('Error placing order. Please try again.');
        setErrorDialogOpen(true);
    }
};

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
        <Header sx={{ display: 'flex' }} search={searchTerm} onSearchChange={handleSearchChange} />
        <Grid container sx={{ height: 'calc(100% - 64px)' }}>
          <Grid item xs={12} md={8} sx={{ height: '100%', overflow: 'hidden', paddingBottom: '30px' }}>
            <Menu 
              menuItems={menuItems} 
              addToCart={addToCart} 
              removeFromCart={removeFromCart} 
              searchTerm={searchTerm}
              selectedCategories={selectedCategories}
              onCategoryToggle={handleCategoryToggle}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: '100%', overflow: 'auto' }}>
            <Paper elevation={3} sx={{ 
              position: 'sticky', 
              top: 0, 
              p: 2, 
              maxHeight: 'calc(100vh - 64px)', 
              overflowY: 'auto' 
            }}>
              <Typography variant="h6" gutterBottom>
                Orden
              </Typography>
              {cartItems.map((item) => (
                <Box key={item.firebaseId || item.id} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <img src={item.image} alt={item.name} style={{ width: 70, height: 50, marginRight: 10, borderTopRightRadius: 10, borderTopLeftRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} />
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">{item.name}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton onClick={() => removeFromCart(item)} size="small">
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="body1" sx={{ mx: 1 }}>
                        {item.quantity}
                      </Typography>
                      <IconButton onClick={() => addToCart(item)} size="small">
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  {/* KEEP THIS FOR FUTURE USE */}
                  {/* <Typography variant="subtitle1">${(item.price * item.quantity).toFixed(2)}</Typography> */}
                </Box>
              ))}
              <Box sx={{ mt: 2 }}>
                {/* KEEP THIS FOR FUTURE USE */}
                {/* <Typography variant="subtitle1">Total: ${total.toFixed(2)}</Typography> */}
                <Button 
                  variant="contained" 
                  fullWidth 
                  sx={{ 
                    mt: 2, 
                    backgroundColor: '#d60f16',
                    '&:hover': {
                      backgroundColor: '#b60d13',
                    },
                  }} 
                  onClick={handlePlaceOrder}
                >
                  Enviar Orden
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <PreMenuModal
        open={preMenuModalOpen}
        onClose={() => setPreMenuModalOpen(false)}
        onCategorySelect={handleCategorySelect}
      />
      <OrderModal
        open={orderModalOpen}
        onClose={() => setOrderModalOpen(false)}
        onSubmit={handleOrderSubmit}
        userInfo={userInfo}
        isNewUser={!userInfo}
        userPhone={userPhone} // Pass userPhone to OrderModal
      />
      <LoginModal
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        onLogin={handleLogin}
        phoneInput={phoneInput}
        onPhoneInputChange={(value) => setPhoneInput(value)}
        userPhone={userPhone} // Pass userPhone to LoginModal
      />
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Orden enviada correctamente!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {
          setConfirmDialogOpen(false);
          refreshPage();
        }} sx={{ color: '#d60f16',
          '&:hover': {
            color: '#d60f16', backgroundColor: '#d60f160a',
          },
        }}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
      >
        <DialogTitle>Oops!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialogOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HomePage;