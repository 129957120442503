// src/context/AdminAuthContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { adminLogin, adminLogout } from '../services/api';

const AdminAuthContext = createContext();

export const useAdminAuth = () => useContext(AdminAuthContext);

export const AdminAuthProvider = ({ children }) => {
  const [adminUser, setAdminUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check for existing session on component mount
    const checkSession = async () => {
      try {
        // You'll need to implement this endpoint in your backend
        const response = await fetch('http://localhost:5000/api/admin/check-session');
        if (response.ok) {
          const data = await response.json();
          setAdminUser(data.user);
        }
      } catch (error) {
        console.error('Error checking session:', error);
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, []);

  const login = async (email, password) => {
    try {
      const data = await adminLogin({ email, password });
      setAdminUser(data);
      return data;
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await adminLogout();
      setAdminUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AdminAuthContext.Provider value={{ adminUser, loading, login, logout }}>
      {children}
    </AdminAuthContext.Provider>
  );
};
