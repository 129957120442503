import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  IconButton,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrintIcon from '@mui/icons-material/Print';
import Header from '../../components/Header/Header';
import SideMenu from '../../components/SideMenu/SideMenu';
import { fetchOrders, reprintOrder } from '../../services/api';
import OrderDetailsModal from '../../components/OrderModal/OrderDetailsModal';
import { useAdminAuth } from '../../context/AdminAuthContext';

const OrderHistoryPage = () => {
  const [orders, setOrders] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [quickDateFilter, setQuickDateFilter] = useState('');
  const { adminUser } = useAdminAuth();

  const parseDate = (dateString) => {
    if (!dateString) return null;
    let parts;
    if (dateString.includes('/')) {
      parts = dateString.split('/');
      return new Date(parts[2], parts[1] - 1, parts[0]);
    } else if (dateString.includes('-')) {
      parts = dateString.split('-');
      return new Date(parts[0], parts[1] - 1, parts[2]);
    }
    return null;
  };

  const refreshOrders = async () => {
    try {
      const updatedOrders = await fetchOrders();
      setOrders(updatedOrders);
      
      // If there's a selected order, update it with the new data
      if (selectedOrder) {
        const updatedSelectedOrder = updatedOrders[selectedOrder.orderId];
        setSelectedOrder(updatedSelectedOrder);
      }
    } catch (error) {
      console.error('Error refreshing orders:', error);
    }
  };

  useEffect(() => {
    const fetchOrdersData = async () => {
      if (!adminUser) return; // Don't fetch if not authenticated
      
      try {
        const response = await fetchOrders();
        console.log('Response:', response);
  
        if (response && typeof response === 'object' && Object.keys(response).length > 0) {
          const ordersArray = Object.values(response);
          setOrders(ordersArray);
  
          // Set quick date filter to 'yesterdayAndToday'
          setQuickDateFilter('yesterdayAndToday');
          handleQuickDateFilterChange({ target: { value: 'yesterdayAndToday' } });
        } else {
          console.log('No orders found.');
          setOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setOrders([]);
      }
    };
  
    fetchOrdersData();
  }, [adminUser]);

  const handlePrintOrder = async (orderId) => {
    try {
      await reprintOrder(orderId);
      alert('Order printed successfully');
    } catch (error) {
      console.error('Error printing order:', error);
      alert('Error printing order. Please try again.');
    }
  };

  const handleQuickDateFilterChange = (event) => {
    const value = event.target.value;
    setQuickDateFilter(value);
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    switch (value) {
      case 'today':
        setStartDate(today);
        setEndDate(today);
        break;
      case 'yesterdayAndToday':
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setStartDate(yesterday);
        setEndDate(today);
        break;
      case 'thisWeek':
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + 1);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        break;
      case 'thisMonth':
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
        break;
      default:
        setStartDate(null);
        setEndDate(null);
    }
  };

  const handleOpen = (orderId) => {
    console.log('Attempting to open modal for order:', orderId);
    const orderToShow = orders[orderId];
    console.log('Order data:', orderToShow);
    setSelectedOrder(orderToShow);
    setOpen(true);
    console.log('Modal should be open now. Open state:', true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleOrderStatusChange = (event) => {
    setOrderStatus(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const filteredOrders = Object.entries(orders).filter(([orderId, order]) => {
    const matchesSearch = Object.values(order).some(
      value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const matchesOrderStatus = !orderStatus || order.orderStatus === orderStatus;
    const matchesPaymentStatus = !paymentStatus || order.paymentStatus === paymentStatus;
    
    const orderDate = parseDate(order.orderPlacementDate);
    
    const isAfterStartDate = !startDate || orderDate >= startDate;
    const isBeforeEndDate = !endDate || orderDate <= endDate;
    
    return matchesSearch && matchesOrderStatus && matchesPaymentStatus && isAfterStartDate && isBeforeEndDate;
  });

  const getStatusStyle = (status, type) => {
    if (type === 'payment') {
      switch (status) {
        case 'Facturado':
          return { backgroundColor: 'green', borderRadius: '4px', color: 'white', };
        case 'Pendiente':
          return { backgroundColor: '#757575', borderRadius: '4px', color: 'white' };
        default:
          return {};
      }
    } else if (type === 'order') {
      switch (status) {
        case 'Completado':
          return { backgroundColor: 'transparent', border: '1px solid green', borderRadius: '4px', color: 'green' };
        case 'Cancelado':
          return { backgroundColor: '#d60f16', borderRadius: '4px', color: 'white', };
        case 'Entregado':
          return { backgroundColor: 'green', borderRadius: '4px', color: 'white', };
        case 'Procesando':
          return { backgroundColor: 'orange', borderRadius: '4px', color: 'white', };
        case 'Pendiente':
          return { backgroundColor: 'transparent', border: '1px solid orange', borderRadius: '4px', color: 'orange' };
        default:
          return {};
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Header search={searchTerm} onSearchChange={handleSearchChange} />
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl variant="outlined">
              <InputLabel id="order-status-label">Estado</InputLabel>
              <Select
                labelId="order-status-label"
                value={orderStatus}
                onChange={handleOrderStatusChange}
                label="Estado"
                size="small"
              >
                <MenuItem value="">Todo</MenuItem>
                <MenuItem value="Pendiente">Pendiente</MenuItem>
                <MenuItem value="Procesando">Procesando</MenuItem>
                <MenuItem value="Completado">Completado</MenuItem>
                <MenuItem value="Entregado">Entregado</MenuItem>
                <MenuItem value="Cancelado">Cancelado</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="payment-status-label">Pago</InputLabel>
              <Select
                labelId="payment-status-label"
                value={paymentStatus}
                onChange={handlePaymentStatusChange}
                label="Pago"
                size="small"
              >
                <MenuItem value="">Todo</MenuItem>
                <MenuItem value="Facturado">Facturado</MenuItem>
                <MenuItem value="Pendiente">Pendiente</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <FormControl variant="outlined">
              <InputLabel id="quick-filter-label">Filtro rápido</InputLabel>
              <Select
                labelId="quick-filter-label"
                value={quickDateFilter}
                onChange={handleQuickDateFilterChange}
                label="Filtro rápido"
                size="small"
              >
                <MenuItem value="">Ninguno</MenuItem>
                <MenuItem value="today">Hoy</MenuItem>
                <MenuItem value="yesterdayAndToday">Ayer y Hoy</MenuItem>
                <MenuItem value="thisWeek">Esta Semana</MenuItem>
                <MenuItem value="thisMonth">Este Mes</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Fecha Inicio"
                value={startDate}
                onChange={handleStartDateChange}
                slotProps={{
                  textField: {
                    size: "small",
                  }
                }}
              />
              <DatePicker
                label="Fecha Fin"
                value={endDate}
                onChange={handleEndDateChange}
                slotProps={{
                  textField: {
                    size: "small",
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="order history table">
              <TableHead>
                <TableRow>
                  <TableCell>Orden #</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>Fecha</TableCell>
                  {/* <TableCell>Total</TableCell> */}
                  <TableCell>Pago</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell align="center">Acciónes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders.map(([orderId, order]) => {
                  // const total = calculateTotal(order.cart);
                  return (
                    <TableRow key={orderId}>
                      <TableCell component="th" scope="row">
                        {order.orderId}
                      </TableCell>
                      <TableCell>{order.name}</TableCell>
                      <TableCell>{order.phone}</TableCell>
                      <TableCell>{order.orderPlacementDate}</TableCell>
                      {/* <TableCell>${total.toFixed(2)}</TableCell> */}
                      <TableCell>
                        <Chip
                          label={order.paymentStatus}
                          sx={getStatusStyle(order.paymentStatus, 'payment')}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={order.orderStatus}
                          sx={getStatusStyle(order.orderStatus, 'order')}
                        />
                      </TableCell>
                      <TableCell align="center">
                      <Button 
                        variant="contained" 
                        sx={{ 
                          backgroundColor: '#d60f16',
                          '&:hover': {
                            backgroundColor: '#b60d13',
                          },
                          mr: 1
                        }} 
                        onClick={() => handleOpen(orderId)}
                      >
                        Detalles
                      </Button>
                      <Tooltip title="Imprimir orden">
                        <IconButton 
                          onClick={() => handlePrintOrder(order.orderId)}
                          color="#757575"
                        >
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <OrderDetailsModal
        open={open}
        handleClose={handleClose}
        selectedOrder={selectedOrder}
        refreshOrders={() => fetchOrders()}
      />
    </Box>
  );
};

// const calculateTotal = (cart) => {
//   if (!Array.isArray(cart)) {
//     console.warn('Cart is not an array:', cart);
//     return 0;
//   }
//   return cart.reduce((total, item) => total + item.price * item.quantity, 0);
// };

export default OrderHistoryPage;
