import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const LoginModal = ({ open, onClose, onLogin, phoneInput, onPhoneInputChange }) => {
  const [formattedPhone, setFormattedPhone] = useState(phoneInput);

  useEffect(() => {
    formatPhoneNumber(phoneInput);
  }, [phoneInput]);

  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters
    let cleaned = input.replace(/\D/g, '');
    
    // If the number doesn't start with 1, add it
    if (!cleaned.startsWith('1')) {
      cleaned = '1' + cleaned;
    }
    
    // Format the number with +1 prefix
    const formatted = '+' + cleaned;
    
    setFormattedPhone(formatted);
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    onPhoneInputChange(input);
  };

  const handleLogin = () => {
    onLogin(formattedPhone);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4, boxShadow: 24 }}>
        <Typography variant="h6" gutterBottom>Acceso</Typography>
        <TextField 
          fullWidth 
          label="Teléfono" 
          value={formattedPhone} 
          onChange={handlePhoneChange} 
          margin="normal"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
          helperText="Formato: +1XXXXXXXXXX"
        />
        <Button 
          variant="contained" 
          fullWidth 
          color="primary" 
          onClick={handleLogin} 
          sx={{ mt: 2, backgroundColor:'#d60f16', '&:hover': { backgroundColor: '#b60d13' } }}
          disabled={formattedPhone.length < 12}  // +1 plus 10 digits
        >
          Acceder
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;