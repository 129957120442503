import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d60f16',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
            borderWidth: '1px',
          },
        },
        input: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(14px, 9px) scale(1)',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'black',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: 150,
          '& .MuiInputBase-root:not(.MuiInputBase-multiline)': {
            height: 40,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#b60d13',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
            filter: 'invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(96%)',
          },
          '& .MuiInputBase-root:not(.MuiInputBase-multiline)': {
            height: 40,
          },
          '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
            paddingTop: 0,
            paddingBottom: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
  },
});

export default theme;