// src/components/Admin/ProfileModal.js

import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useAdminAuth } from '../../context/AdminAuthContext';
import { useNavigate } from 'react-router-dom';
import AdminRegisterModal from './AdminRegisterModal';

const ProfileModal = ({ open, handleClose }) => {
  const { adminUser, logout } = useAdminAuth();
  const navigate = useNavigate();
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      handleClose();
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleOpenRegisterModal = () => {
    setRegisterModalOpen(true);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modalStyle, width: 400 }}>
          <Typography variant="h6" component="h2">
            Perfil
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Nombre: {adminUser?.name}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Correo: {adminUser?.email}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Role: {adminUser?.role}
          </Typography>
          <Button variant="contained" sx={{ mt: 2, backgroundColor: '#d60f16',
                            '&:hover': {
                              backgroundColor: '#b60d13',
                            }, }} onClick={handleLogout}>
            Cerrar Sesión
          </Button>
          {adminUser?.role === 'supervisor' && (
            <Button variant="contained" color="primary" sx={{ mt: 2, ml: 2 }} onClick={handleOpenRegisterModal}>
              Registrar Usuario
            </Button>
          )}
        </Box>
      </Modal>
      <AdminRegisterModal 
        open={registerModalOpen} 
        handleClose={() => setRegisterModalOpen(false)} 
      />
    </>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default ProfileModal;