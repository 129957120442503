import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import theme from './theme/theme';
import HomePage from './pages/HomePage/HomePage';
import OrderHistoryPage from './pages/OrderHistoryPage/OrderHistoryPage';
import { AdminAuthProvider, useAdminAuth } from './context/AdminAuthContext';
import AdminProductPage from './pages/AdminProductPage/AdminProductPage';

const ProtectedRoute = ({ children }) => {
  const { adminUser, loading } = useAdminAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!adminUser || !['servicio', 'operativo', 'supervisor'].includes(adminUser.role)) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AdminAuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route 
                path="/orderHistory" 
                element={
                  <ProtectedRoute>
                    <OrderHistoryPage />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/admin/products" 
                element={
                  <ProtectedRoute allowedRoles={['supervisor']}>
                    <AdminProductPage />
                  </ProtectedRoute>
                } 
              />
            </Routes>
          </BrowserRouter>
        </AdminAuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;