import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { triggerEvent, updateOrder, cancelOrder, fetchProducts, addOrderHistoryEvents } from '../../services/api';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAdminAuth } from '../../context/AdminAuthContext';
import { styled, keyframes } from '@mui/material/styles';

const pulse = keyframes`
  0% {
    background-color: inherit;
  }
  50% {
    background-color: rgba(214, 15, 22, 0.1);
  }
  100% {
    background-color: inherit;
  }
`;

const AnimatedTableCell = styled(TableCell)(({ theme }) => ({
  transition: theme.transitions.create('background-color'),
  '&.pulse': {
    animation: `${pulse} 0.5s`,
  },
}));

const OrderDetailsModal = ({ open, handleClose, selectedOrder, refreshOrders }) => {
  console.log('OrderDetailsModal rendered with props:', { open, selectedOrder });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [localOrder, setLocalOrder] = useState(null);
  const { adminUser } = useAdminAuth();
  const [facturaDialogOpen, setFacturaDialogOpen] = useState(false);
  const [facturaError, setFacturaError] = useState('');
  const [facturaNumber, setFacturaNumber] = useState('');
  const isOrderCancelled = localOrder?.orderStatus === 'Cancelado';
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);
  const [deliverConfirmOpen, setDeliverConfirmOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [lastChangedItem, setLastChangedItem] = useState(null);
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [pendingHistoryEvents, setPendingHistoryEvents] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);

  const addPendingHistoryEvent = (event) => {
    const newHistoryEntry = {
      date: new Date().toISOString(),
      event: event,
      userName: adminUser ? adminUser.name : 'Sistema'
    };
    setPendingHistoryEvents(prev => [...prev, newHistoryEntry]);
  };

  const isAddProductDisabled = 
  !localOrder || 
  localOrder.paymentStatus === 'Facturado' || 
  localOrder.orderStatus === 'Cancelado' || 
  localOrder.orderStatus === 'Entregado';

  const handleAddProduct = () => {
    if (!isAddProductDisabled) {
      setSelectedProduct('');
      setAddProductDialogOpen(true);
    }
  };

  const handleProductSelect = (event) => {
    setSelectedProduct(event.target.value);
  };

  const confirmAddProduct = () => {
    if (selectedProduct) {
      const productToAdd = availableProducts.find(p => p.firebaseId === selectedProduct);
      
      setLocalOrder(prevOrder => ({
        ...prevOrder,
        cart: [...prevOrder.cart, { ...productToAdd, quantity: 1 }]
      }));

      addPendingHistoryEvent(`Producto agregado: ${productToAdd.name} (${productToAdd.codigo})`);

      setUnsavedChanges(true);
      setAddProductDialogOpen(false);
      setSelectedProduct('');
    }
  };

  const handleQuantityChange = (itemId, change) => {
    if (isOrderCancelled || localOrder.orderStatus === 'Entregado') return;
    
    setLocalOrder(prevOrder => {
      const updatedCart = prevOrder.cart.map(item => {
        if (item.firebaseId === itemId) {
          const newQuantity = Math.max(0, item.quantity + change);
          // if (newQuantity !== item.quantity) {
          //   addPendingHistoryEvent(`Cantidad cambiada para ${item.name}: ${item.quantity} ${change > 0 ? '+' : '-'} ${Math.abs(change)}`);
          // }
          if (newQuantity !== item.quantity) {
            addPendingHistoryEvent(`Cantidad de ${item.name} cambio de ${item.quantity} a ${newQuantity}`);
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      }).filter(item => item.quantity > 0);

      return { ...prevOrder, cart: updatedCart };
    });

    setUnsavedChanges(true);
    setLastChangedItem(itemId);
    setTimeout(() => setLastChangedItem(null), 500);
  };

  const handleDeleteItem = (item) => {
    setItemToDelete(item);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = () => {
    if (itemToDelete) {
      handleQuantityChange(itemToDelete.firebaseId, -itemToDelete.quantity);
    }
    setDeleteConfirmOpen(false);
    setItemToDelete(null);
  };

  const canStartOrder = () => {
    return localOrder.paymentStatus === 'Facturado' && 
           localOrder.orderStatus !== 'Procesando' && 
           localOrder.orderStatus !== 'Completado' &&
           localOrder.orderStatus !== 'Entregado' &&
           !isOrderCancelled;
  };

  const handleDeliverOrder = () => {
    setDeliverConfirmOpen(true);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleCodigoChange = (itemId, newCodigo) => {
    if (isOrderCancelled || localOrder.paymentStatus !== 'Pendiente') return;
    
    const selectedProduct = products.find(product => product.codigo === newCodigo);
    
    if (!selectedProduct) {
      console.error('Selected product not found');
      return;
    }
    
    const updatedCart = localOrder.cart.map(item => 
      item.id === itemId ? { 
        ...item, 
        codigo: newCodigo,
        name: selectedProduct.name,
        price: selectedProduct.price,
        image: selectedProduct.image
      } : item
    );
    
    setLocalOrder(prevOrder => ({ ...prevOrder, cart: updatedCart }));
    setUnsavedChanges(true);
  };

  useEffect(() => {
    if (open) {
      fetchProducts().then(setProducts).catch(console.error);
    }
  }, [open]);

  useEffect(() => {
    if (localOrder && products.length > 0) {
      const currentProductIds = new Set(localOrder.cart.map(item => item.firebaseId));
      const filtered = products.filter(product => !currentProductIds.has(product.firebaseId));
      setAvailableProducts(filtered);
    }
  }, [localOrder, products]);

  useEffect(() => {
    if (selectedOrder) {
      setLocalOrder(selectedOrder);
      setPendingHistoryEvents([]);
      setFacturaNumber(selectedOrder.factura || '');
      setIsOrderCompleted(selectedOrder.orderStatus === 'Completado' || selectedOrder.orderStatus === 'Entregado');
      setButtonDisabled(
        selectedOrder.orderStatus !== 'Procesando' || 
        isOrderCancelled || 
        selectedOrder.orderStatus === 'Entregado'
      );
      setUnsavedChanges(false);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (selectedOrder && selectedOrder.factura) {
      setFacturaNumber(selectedOrder.factura);
    } else {
      setFacturaNumber('');
    }
  }, [selectedOrder]);

  // Update localOrder when selectedOrder changes
  useEffect(() => {
    setLocalOrder(selectedOrder);
  }, [selectedOrder]);

  if (!localOrder || !selectedOrder) return null;
  const cartItems = localOrder.cart || [];

  const confirmDeliverOrder = async () => {
    try {
      const updatedOrder = { 
        ...localOrder, 
        orderStatus: 'Entregado'
      };
      await updateOrder(updatedOrder);
      setLocalOrder(updatedOrder);
      // await refreshOrders();
      refreshPage();
      setDeliverConfirmOpen(false);
    } catch (error) {
      console.error('Error delivering order:', error);
    }
  };

  const handleUploadFactura = async () => {
    if (facturaNumber.length === 7) {
      try {
        const updatedOrder = { 
          ...localOrder, 
          factura: facturaNumber, 
          paymentStatus: 'Facturado'  // Update payment status
        };
        await updateOrder(updatedOrder);
        setLocalOrder(updatedOrder);
        await refreshOrders();
        // refreshPage();
        handleCloseFacturaDialog();
      } catch (error) {
        console.error('Error updating order with factura:', error);
      }
    } else {
      setFacturaError('El número de factura debe tener 7 dígitos');
    }
  };

  const handleStartOrder = async () => {
    if (!canStartOrder()) return;
  
    try {
      const updatedOrder = { 
        ...localOrder, 
        orderStatus: 'Procesando'
      };
      await updateOrder(updatedOrder);
      setLocalOrder(updatedOrder);
      // await refreshOrders();
      refreshPage();
    } catch (error) {
      console.error('Error starting order:', error);
    }
  };

  const handleOpenFacturaDialog = () => {
    setFacturaDialogOpen(true);
  };

  const handleCloseFacturaDialog = () => {
    setFacturaDialogOpen(false);
    setFacturaNumber('');
    setFacturaError('');
  };

  const handleFacturaSubmit = () => {
    if (facturaNumber.length === 7) {
      setFacturaDialogOpen(false);
      setConfirmOpen(true);
    } else {
      setFacturaError('El número de factura debe tener 7 dígitos');
    }
  };

  const handleTriggerEvent = async () => {
    if (!localOrder || (localOrder.orderStatus !== 'Procesando' && !isOrderCompleted)) return;
  
    try {
      const response = await triggerEvent(localOrder.phone, localOrder.orderId);
      console.log('Event triggered successfully:', response);
      
      if (!isOrderCompleted) {
        const updatedOrder = { ...localOrder, orderStatus: 'Completado' };
        await updateOrder(updatedOrder);
        setLocalOrder(updatedOrder);
        setIsOrderCompleted(true);
      }
      
      setButtonDisabled(false);  // Enable the button for "Recordar Cliente"
      setConfirmOpen(false);
      // await refreshOrders();
      refreshPage();
    } catch (error) {
      console.error('Error triggering event:', error);
    }
  };

  const handleOpenConfirm = () => {
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleSaveOrder = async () => {
    try {
      const updatedOrder = {
        ...localOrder,
        userName: adminUser ? adminUser.name : 'Sistema'
      };

      // First, update the order
      await updateOrder(updatedOrder);

      // Then, if there are any pending history events, add them
      if (pendingHistoryEvents.length > 0) {
        await addOrderHistoryEvents(localOrder.orderId, pendingHistoryEvents);
        
        // Update local state to include new history events
        setLocalOrder(prevOrder => ({
          ...prevOrder,
          history: [...(prevOrder.history || []), ...pendingHistoryEvents]
        }));

        // Clear pending history events
        setPendingHistoryEvents([]);
      }

      setUnsavedChanges(false);
      refreshOrders();
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const handleCloseSaveOrder = async () => {
    try {
      await updateOrder(localOrder);
      setUnsavedChanges(false);
      handleClose();
      refreshPage();
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const handleCloseModal = () => {
    if (unsavedChanges) {
      setShowUnsavedChangesDialog(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setShowUnsavedChangesDialog(false);
    setUnsavedChanges(false);
    handleClose();
  };

  const handleCancelClose = () => {
    setShowUnsavedChangesDialog(false);
  };

  const handleCancelOrder = async () => {
    try {
      const updatedOrder = { ...localOrder, orderStatus: 'Cancelado' };
      await updateOrder(updatedOrder);
      setLocalOrder(updatedOrder);
      // await refreshOrders();
      refreshPage();
    } catch (error) {
      console.error('Error canceling order:', error);
    }
  };

  const canModifyQuantity = adminUser && (adminUser.role === 'servicio' || adminUser.role === 'supervisor' || adminUser.role === 'operativo');
  const canReduceQuantity = adminUser && (adminUser.role === 'servicio' || adminUser.role === 'supervisor');
  const canCancelOrder = adminUser && (adminUser.role === 'servicio' || adminUser.role === 'supervisor');

  if (!localOrder || !selectedOrder) return null;

  return (
    <>
    <Dialog
      open={open}
      onClose={handleCloseModal}
      BackdropProps={{
        onClick: handleCloseModal,
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '600px',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
    >
      <Box
        sx={{
          p: 2,
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" fontWeight="bold">
            Detalles de Orden
          </Typography>
          <Button onClick={handleCloseModal} sx={{ minWidth: 'auto', p: 1, '&:hover': { backgroundColor: '#b60d1308' } }}>
            <ClearIcon sx={{ color: '#d60f16' }} />
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          #{selectedOrder.orderId}
        </Typography>
        {isOrderCancelled && (
          <Typography variant="body1" color="error" sx={{ mt: 1 }}>
            Esta orden ha sido cancelada.
          </Typography>
        )}
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>

      <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
            Factura
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Numero: {facturaNumber || 'Sin facturar.'}
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
            Cliente
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedOrder.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedOrder.phone}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedOrder.email}
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
            Fechas
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Fecha de Orden: {selectedOrder.orderPlacementDate}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Fecha de Entrega: {new Date(selectedOrder.deliveryDate).toLocaleString()}
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
            Productos
          </Typography>
          {cartItems.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Codigo</TableCell>
                    <TableCell align="right">Ctd</TableCell>
                    {/* <TableCell align="right">Precio</TableCell> */}
                    {canModifyQuantity && <TableCell align="right">Acción</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((item) => (
                    <TableRow key={item.firebaseId}>
                      <AnimatedTableCell className={lastChangedItem === item.firebaseId ? 'pulse' : ''}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: 1,
                              backgroundColor: '#f0f0f0',
                              mr: 2,
                              backgroundImage: `url(${item.image})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          />
                          <Typography variant="body2">{item.name}</Typography>
                        </Box>
                      </AnimatedTableCell>
                      <AnimatedTableCell align="right" className={lastChangedItem === item.firebaseId ? 'pulse' : ''}>
                        {adminUser.role === 'operativo' && localOrder.paymentStatus === 'Pendiente' ? (
                          <Select
                            value={item.codigo || ''}
                            onChange={(e) => handleCodigoChange(item.id, e.target.value)}
                            disabled={isOrderCancelled || localOrder.orderStatus === 'Entregado'}
                            renderValue={(value) => value}
                          >
                            {products.map((product) => (
                              <MenuItem key={product.firebaseId} value={product.codigo}>
                                {product.codigo} - {product.name}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          item.codigo || 'N/A'
                        )}
                      </AnimatedTableCell>
                      <AnimatedTableCell align="right" className={lastChangedItem === item.firebaseId ? 'pulse' : ''}>
                        {item.quantity}
                      </AnimatedTableCell>
                      {/* <TableCell align="right">${item.price.toFixed(2)}</TableCell> */}
                      {canModifyQuantity && !isOrderCancelled && localOrder.orderStatus !== 'Entregado' && (
                        <TableCell align="right">
                          {adminUser.role === 'operativo' && (
                            <IconButton 
                              onClick={() => handleQuantityChange(item.firebaseId, 1)} 
                              size="small"
                              disabled={localOrder.orderStatus === 'Entregado'}
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                          {canReduceQuantity && (
                            <>
                              <IconButton 
                                onClick={() => handleQuantityChange(item.firebaseId, -1)} 
                                size="small"
                                disabled={localOrder.orderStatus === 'Entregado'}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <IconButton 
                                onClick={() => handleDeleteItem(item)} 
                                size="small"
                                disabled={localOrder.orderStatus === 'Entregado'}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No hay productos asociados con esta orden.
            </Typography>
          )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Tooltip title={isAddProductDisabled ? "No se pueden agregar productos a órdenes facturadas, canceladas o entregadas" : ""}>
              <span>
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddProduct}
                  disabled={isAddProductDisabled}
                  sx={{
                    borderColor: '#d60f16',
                    color: '#d60f16',
                    '&:hover': {
                      borderColor: '#b60d13',
                      backgroundColor: 'rgba(214, 15, 22, 0.04)',
                    },
                  }}
                >
                  Agregar Producto
                </Button>
              </span>
            </Tooltip>
            <Button
              variant="contained"
              onClick={handleSaveOrder}
              disabled={!unsavedChanges}
              sx={{
                backgroundColor: '#d60f16',
                '&:hover': { backgroundColor: '#b60d13' },
                '&:disabled': { backgroundColor: '#f5a5a8' }
              }}
            >
              Guardar Orden
            </Button>
          </Box>
        </Box>

        <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
          Historial
        </Typography>
          <List>
            {[...(localOrder.history || []), ...pendingHistoryEvents].map((entry, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText
                  primary={entry.event}
                  secondary={`${new Date(entry.date).toLocaleString()} - ${entry.userName}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* PRECIOS ESCONDIDOS */}
        {/* <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
            Factura
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2">Subtotal</Typography>
            <Typography variant="body2">${calculateSubtotal(selectedOrder.cart).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2">ITBIS</Typography>
            <Typography variant="body2">${calculateTax(selectedOrder.cart).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="subtitle2" fontWeight="bold">Total</Typography>
            <Typography variant="subtitle2" fontWeight="bold">${calculateTotal(selectedOrder.cart).toFixed(2)}</Typography>
          </Box>
        </Box> */}
      </Box>

      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between' }}>
        {adminUser.role !== 'operativo' && (
          localOrder.orderStatus === 'Completado' && localOrder.paymentStatus === 'Facturado' && adminUser.role === 'servicio' ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#d60f16',
                '&:hover': { backgroundColor: '#b60d13' },
                flexGrow: 1,
                mr: 1
              }}
              onClick={handleDeliverOrder}
              disabled={localOrder.orderStatus === 'Entregado'}
            >
              Entregar Orden
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#d60f16',
                '&:hover': { backgroundColor: '#b60d13' },
                flexGrow: 1,
                mr: 1
              }}
              onClick={handleOpenFacturaDialog}
              disabled={!!facturaNumber || isOrderCancelled || localOrder.orderStatus === 'Entregado'}
            >
              Subir Factura
            </Button>
          )
        )}
        {adminUser.role === 'operativo' && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#d60f16',
              '&:hover': { backgroundColor: '#b60d13' },
              flexGrow: 1,
              mr: 1
            }}
            onClick={handleStartOrder}
            disabled={!canStartOrder() || localOrder.orderStatus === 'Entregado'}
          >
            Iniciar Orden
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#d60f16',
            '&:hover': { backgroundColor: '#b60d13' },
            flexGrow: 1,
            mx: 1
          }}
          // Keeping for later use.
          // onClick={isOrderCompleted ? handleRemindCustomer : handleOpenConfirm}
          onClick={handleOpenConfirm}
          disabled={
            (localOrder.orderStatus !== 'Procesando' && !isOrderCompleted) || 
            isOrderCancelled || 
            localOrder.orderStatus === 'Entregado'
          }
        >
          {isOrderCompleted ? 'Recordar Cliente' : 'Completar Orden'}
        </Button>
        <Button
          variant="outlined"
          sx={{
            borderColor: '#d60f16',
            color: '#d60f16',
            '&:hover': {
              borderColor: '#b60d13',
              backgroundColor: 'rgba(214, 15, 22, 0.04)',
            },
            flexGrow: 1,
            ml: 1
          }}
          onClick={handleCancelOrder}
          disabled={!canCancelOrder || isOrderCancelled || localOrder.orderStatus === 'Entregado'}
        >
          Cancelar Orden
        </Button>
      </Box>
    </Dialog>

    <Dialog
      open={deliverConfirmOpen}
      onClose={() => setDeliverConfirmOpen(false)}
    >
      <DialogTitle>Confirmar Entrega</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor confirmar si la orden fue entregada.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeliverConfirmOpen(false)}>Cancelar</Button>
        <Button onClick={confirmDeliverOrder} color="primary">Confirmar</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={facturaDialogOpen} onClose={handleCloseFacturaDialog}>
      <DialogTitle>Subir Factura</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Número de Factura"
          type="text"
          fullWidth
          value={facturaNumber}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
            setFacturaNumber(value);
            setFacturaError('');
          }}
          error={!!facturaError}
          helperText={facturaError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseFacturaDialog} sx={{ color: '#d60f16',
                            '&:hover': {
                              color: '#d60f16', backgroundColor: '#d60f160a',
                            }, }}>Cancelar</Button>
        <Button onClick={handleUploadFactura} disabled={facturaNumber.length !== 7} sx={{
                backgroundColor: '#d60f16',
                color: '#ffffff',
                '&:hover': { backgroundColor: '#b60d13' },
                '&:disabled': { backgroundColor: '#f5a5a8' }
              }}>
          Subir
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={confirmOpen}
      onClose={handleCloseConfirm}
    >
      <DialogTitle>Confirmación</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Realmente está lista la orden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirm}>No</Button>
        <Button onClick={handleTriggerEvent} color="primary">Sí</Button>
      </DialogActions>
    </Dialog>

    <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de que desea eliminar {itemToDelete?.name} de la orden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancelar</Button>
          <Button onClick={confirmDelete} color="error">Eliminar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addProductDialogOpen} onClose={() => setAddProductDialogOpen(false)}>
        <DialogTitle>Agregar Producto</DialogTitle>
        <DialogContent>
          {availableProducts.length > 0 ? (
            <Select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Seleccionar Producto
              </MenuItem>
              {availableProducts.map((product) => (
                <MenuItem key={product.firebaseId} value={product.firebaseId}>
                  {product.name} - {product.codigo}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Typography>No hay productos disponibles para agregar.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddProductDialogOpen(false)} sx={{ color: '#d60f16',
                            '&:hover': {
                              color: '#d60f16', backgroundColor: '#d60f160a',
                            }, }}>Cancelar</Button>
          <Button onClick={confirmAddProduct} disabled={!selectedProduct || availableProducts.length === 0} sx={{
                backgroundColor: '#d60f16',
                color: '#ffffff',
                '&:hover': { backgroundColor: '#b60d13' },
                '&:disabled': { backgroundColor: '#f5a5a8' }
              }}>
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

    <Dialog
        open={showUnsavedChangesDialog}
        onClose={handleCancelClose}
      >
        <DialogTitle>Cambios sin guardar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea guardar los cambios antes de cerrar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose}>Cancelar</Button>
          <Button onClick={handleConfirmClose}>Descartar cambios</Button>
          <Button onClick={handleCloseSaveOrder} autoFocus>
            Guardar cambios
          </Button>
        </DialogActions>
      </Dialog>
  </>
  );
};

const calculateSubtotal = (cart = []) => {
  return cart.reduce((total, item) => total + item.price * item.quantity, 0);
};

const calculateTax = (cart = []) => {
  const subtotal = calculateSubtotal(cart);
  const tax = .18;
  return subtotal * tax;
};

const calculateTotal = (cart = []) => {
  const subtotal = calculateSubtotal(cart);
  const shipping = 0;
  const tax = 1.18;
  return (subtotal + shipping) * tax;
};

export default OrderDetailsModal;